<template>
  <v-card outlined width="1500">
    <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
      <v-icon class="mr-2" color="white">mdi-account</v-icon> Informations personnelles
    </v-card-title>

    <v-card-text class="pa-5 my-5">
      <v-form ref="formClientInformations" lazy-validation>
        <v-row class="justify-center">
          <v-col cols="12" md="3">
            <v-select dense :items="['Homme', 'Femme']" outlined label="Civilité" :rules="civilityRules"
              prepend-inner-icon="mdi-gender-male-female" v-model="customerData.civility">
            </v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense outlined label="Prénom" prepend-inner-icon="mdi-account" v-model="customerData.firstname" :rules="firstnameRules">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense outlined label="Nom" prepend-inner-icon="mdi-account" v-model="customerData.lastname" :rules="lastnameRules">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" md="4">
            <v-text-field dense outlined label="Numéro de téléphone"
              prepend-inner-icon="mdi-phone" v-model="customerData.phone" :rules="phoneRules">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field dense outlined label="Email" prepend-inner-icon="mdi-mail" v-model="customerData.email">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" md="4">
            <v-autocomplete :loading="isLoading" :search-input.sync="search" :items="cityItems"
              outlined v-model="customerData.city" :value="customerData.zip" label="Commune"
              clearable hide-details hide-selected prepend-inner-icon="mdi-home" dense no-filter :rules="zipRules">
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title> Rechercher une ville... </v-list-item-title>
                </v-list-item>
              </template>

              <template v-slot:item="{ item }">
                <v-list-item-content>   
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item-content>
              </template></v-autocomplete>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field dense outlined label="Adresse complète"
              prepend-inner-icon="mdi-map-marker" v-model="customerData.address" :rules="addressRules">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" md="3">
            <v-text-field prepend-inner-icon="mdi-calendar" outlined dense
              v-model="customerData.born" label="Date de naissance" :rules="dateBeginRules"
              data-provide="datepicker" type="date">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field outlined label="Loisir" prepend-inner-icon="mdi-shoe-sneaker" dense v-model="customerData.hobby"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox label="Client VIP" v-model="customerData.vip" dense></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text></v-card-text>
    <v-spacer></v-spacer>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn @click="lastStep(2)" elevation="0">
        <v-icon left> mdi-chevron-left </v-icon>
        <span>Précédent</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="verifBeforeAddCustomerPar" outlined>
        <i class="fa-solid fa-user-plus mr-2"></i>
        <span>Ajouter</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import config from "@/views/config/config"
  import {
    defineComponent
  } from "@vue/composition-api"

  export default defineComponent({
    data: () => ({
      siretNotFound: false,
      siretNotFoundMessage: "",

      siret: null,
      dataPappers: false,

      prospect: [],

      isLoading: false,
      search: null,
      cityItems: [],

      customerData: {
        civility: "",
        lastname: "",
        firstname: "",
        phone: "",
        email: "",
        city: "",        
        zip: "",
        address: "",
        born: "",
        hobby: "",
        vip: "",
      },

      civilityRules: [v => !!v || "Une civilité est requise "],
      firstnameRules: [v => !!v || "Un Prénom est requise "],
      lastnameRules: [v => !!v || "Un Nom est requise "],
      phoneRules: [v => !!v || "Un numéro de téléphone est requise "],
      zipRules: [v => !!v || "Une Commune est requise "],
      addressRules: [v => !!v || "Une adresse est requise "],
      dateBeginRules: [v => !!v || "Une date de naissance est requise "],
    }),

    setup() {},

    watch: {
      search(val) {
        if (val.length === 5) {
          try {
            this.isLoading = true

            fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
              .then(res => res.clone().json())
              .then(res => {
                this.cityItems = res.map(item => item.nom)
                this.customerData.zip = val
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false))

            this.successSelectCity = true
            this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
          } catch (e) {
            this.errorSelectCity = true
            this.errorSelectCityMessage = "Une erreur est survenue"
          }
        } else {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
        }
      },
    },

    methods: {
      nextStep() {
        this.$emit("nextStepHome", 3)
      },
      lastStep() {
        this.$emit("lastStepProPart", 3)
      },

      verifBeforeAddCustomerPar(){
        if (this.customerData.civility === ""){
          this.$toast.error(`Erreur, veillez remplir le champ Civilité.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        } else if (this.customerData.firstname === ""){
          this.$toast.error(`Erreur, veillez remplir le champ Prénom.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        } else if (this.customerData.lastname === ""){
          this.$toast.error(`Erreur, veillez remplir le champ Nom.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        } else if (this.customerData.phone === ""){
          this.$toast.error(`Erreur, veillez remplir le champ téléphone.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        } else if (this.customerData.zip === ""){
          this.$toast.error(`Erreur, veillez remplir le champ Commune.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        } else if (this.customerData.address === ""){
          this.$toast.error(`Erreur, veillez remplir le champ Adresse.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        } else if (this.customerData.born === ""){
          this.$toast.error(`Erreur, veillez remplir le champ date de naissance.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        } else {
          this.addCustomerPart()
        }

      },

      async addCustomerPart() {
        let agencyId = localStorage.getItem("agency_id")

        console.log("info : ", agencyId, this.customerData)

        if (this.customerData.vip === true){
          this.customerData.vip = 1
        } else {
          this.customerData.vip = 0
        }

        try {
          let headers = new Headers()

          headers.append("Content-Type", "application/json")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")

          const response = await fetch(
            `${config.apiUri}/agencies/customer_particular?idAgency=${agencyId}&address=${this.customerData.address}&city=${this.customerData.city}
              &postalCode=${this.customerData.zip}&phone=${this.customerData.phone}&email=${this.customerData.email}&born=${this.customerData.born}
              &vip=${this.customerData.vip}&firstname=${this.customerData.firstname}&lastname=${this.customerData.lastname}&civility=${this.customerData.civility}
              &hobby=${this.customerData.hobby}`, {
              mode: "no-cors",
              method: "POST",
              headers: headers,
            },
          )
          console.log(response)
          if (response.status === 400) {
            console.log("Eurreur dans l'envoie !")
          } else {
            console.log("Envoyer ! ", response)
            this.$toast.success(`Le client ${this.customerData.lastname} à bien été ajouté.`, {
              position: "bottom-right",
              timeout: 5000,
            })
            this.customerData = {
              civility: "",
              lastname: "",
              firstname: "",
              phone: "",
              email: "",
              city: "",
              zip: "",
              address: "",
              born: "",
              hobby: "",
              vip: "",
            }
          }
        } catch (e) {
          console.log(e)
          this.$toast.error(`Erreur lors de l'envoie.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      },

      
    },
  })

</script>
