<template>
  <v-container fluid class="no-padding">
    <v-row class="my-12 d-flex">
      <v-col cols="12" md="12" class="text-center">
        <v-row>
          <v-col cols="12" md="7">
            <v-card outlined>
              <v-tabs fixed-tabs centered v-model="tabContrat" slider-size="1" style="box-shadow: none !important">
                <v-tab v-for="tab in tabsContratsTitle" :key="tab.id">
                  {{ tab.title }}
                </v-tab>
              </v-tabs>
            </v-card>
          </v-col>
          <v-col cols="12" md="5" class="d-flex align-center justify-center">
            <v-dialog v-model="dialogAddContrat" persistent max-width="825">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-on:click="dialogAddContrat = true" v-bind="attrs" v-on="on">
                  <v-icon color="EoleBlue" size="24" class="mr-2">mdi-plus-circle</v-icon>
                  <h3>Ajouter Contrat</h3>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Ajouter contrat</v-card-title>
                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Type de risque *"
                        prepend-inner-icon="mdi-close-octagon"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field outlined label="Compagnie *" prepend-inner-icon="mdi-town-hall"> </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field outlined label="N° de client *" prepend-inner-icon="mdi-account"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="N° de contrat *"
                        prepend-inner-icon="mdi-file-document-edit"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Date de souscription *"
                        prepend-inner-icon="mdi-calendar-range"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Prime annuelle TTC"
                        prepend-inner-icon="mdi-currency-usd"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field outlined label="Montant commission" prepend-inner-icon="mdi-cash"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Taux de commission"
                        prepend-inner-icon="mdi-account-cash"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="green darken-1" text @click="dialogAddContrat = false">Annuler </v-btn>
                  <v-btn color="#52b788" text @click="dialogAddContrat = false">Ajouter</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <v-tabs-items v-model="tabContrat">
          <v-radio-group v-model="selectContrat" row style="margin-left: 50px" class="text-center">
            <v-radio
              style="margin-right: 60px"
              v-for="type in typeContrat"
              :key="type.id"
              :value="`${type.value}`"
              :label="`${type.title}`"
            ></v-radio>
          </v-radio-group>
          <v-tab-item class="text-center my-10 mx-10">
            <v-data-table
              header-text-align="center"
              v-if="this.itemsTableContratsActif.length > 0 && this.selectContrat == 'actif'"
              hide-default-footer
              :headers="headersTableContrats"
              :items="itemsTableContratsActif"
            >
            </v-data-table>
            <v-card-text v-if="this.itemsTableContratsActif.length == 0"
              >Aucun contrat {{ this.selectContrat }} n'a été ajouté</v-card-text
            >

            <v-data-table
              v-if="this.itemsTableContratsResile.length > 0 && this.selectContrat == 'resilie'"
              hide-default-footer
              :headers="headersTableContrats"
              :items="itemsTableContratsResile"
            >
            </v-data-table>
            <v-card-text v-if="this.itemsTableContratsResile.length == 0"
              >Aucun contrat {{ this.selectContrat }} n'a été ajouté</v-card-text
            >

            <div
              v-if="
                (this.itemsTableContratsResile.length > 0 || this.itemsTableContratsActif.length > 0) &&
                this.selectContrat == 'allContrats'
              "
            >
              <v-data-table
                hide-default-footer
                :headers="headersTableContrats"
                :items="itemsTableContratsActif"
              ></v-data-table>
              <v-data-table
                hide-default-footer
                :headers="headersTableContrats"
                :items="itemsTableContratsResile"
              ></v-data-table>
            </div>
            <v-card-text v-if="this.itemsTableContratsResile.length == 0"
              >Aucun contrat {{ this.selectContrat }} n'a été ajouté</v-card-text
            >
          </v-tab-item>

          <v-tab-item class="text-center mx-10 my-10">
            <v-icon size="64">mdi-alert-circle</v-icon>
            <v-card-text class="text-h6">Aucun contrat {{ this.selectContrat }} n'a été ajouté </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  beforeMount() {},
  data: () => ({
    dialogAddContrat: false,

    tabContrat: "",
    tabsContratsTitle: [
      {
        id: 0,
        title: "Contrats intermédiés",
        type: "Ajouter un contrat intermédié",
      },
      {
        id: 1,
        title: "Autres contrats",
        type: "Ajouter un autre contrat connu",
      },
    ],

    typeContrat: [
      {
        id: 0,
        title: "Contrats actifs",
        value: "actif",
      },
      {
        id: 1,
        title: "Contrats résiliés",
        value: "resilie",
      },
      {
        id: 2,
        title: "Tous les contrats",
        value: "allContrats",
      },
    ],
    selectContrat: "actif",

    headersTableContrats: [
      {
        text: "Lien",
        value: "lien",
      },
      {
        text: "Intitulé",
        value: "intituler",
      },
      {
        text: "Assureur",
        value: "assureur",
      },
      {
        text: "Risque",
        value: "risque",
      },
      {
        text: "N° client",
        value: "numClient",
      },
      {
        text: "N° contrat",
        value: "numContrat",
      },
      {
        text: "Prime",
        value: "prime",
      },
      {
        text: "Etat",
        value: "status",
      },
    ],

    itemsTableContratsActif: [
      {
        lien: "Souscripteur",
        intituler: "Mon assurance pro",
        assureur: "Gan",
        risque: "Multirique professionnelle",
        numClient: "1",
        numContrat: "123456789",
        prime: "500",
        status: "Souscrit",
        typeItem: "inter",
      },
      {
        lien: "Souscripteur",
        intituler: "Mon assurance part",
        assureur: "MMA",
        risque: "Bris de glace",
        numClient: "3",
        numContrat: "12107280",
        prime: "50",
        status: "Souscrit",
        typeItem: "inter",
      },
    ],

    itemsTableContratsResile: [
      {
        lien: "Souscripteur",
        intituler: "Mon assurance Voiture",
        assureur: "Axa",
        risque: "Tout risque",
        numClient: "2",
        numContrat: "18992099",
        prime: "200",
        status: "Souscrit",
        typeItem: "inter",
      },
    ],
  }),
}
</script>

<style></style>
