<template>
  <v-card width="1500">
    <v-data-table
      class="elevation-1"
      item-key="id"
      :headers="headers"
      :items="customers"
      hide-default-footer
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card flat>
            <v-card-text class="my-2">
              <v-row>
                <v-col v-for="doc in item.document" :key="doc.id" cols="12" md="4" class="flex d-flex flex-column">
                  <v-card outlined class="flex d-flex flex-column" v-if="doc.path != null && doc.url != null">
                    <v-card-text class="EoleYellow--text text-center">{{ doc.nameDocument }}</v-card-text>
                    <v-card-text>
                      <iframe
                        v-if="doc.extension === 'pdf'"
                        style="width: 100%; height: 200px"
                        :src="`http://${doc.url}`"
                      ></iframe>

                      <v-hover
                        v-slot:default="{ hover }"
                        v-else-if="doc.extension === 'png' || doc.extension === 'jpeg' || doc.extension === 'jpg'"
                      >
                        <v-img
                          style="cursor: pointer"
                          :class="{ 'highlighted mx-auto': hover }"
                          contain
                          width="200"
                          max-height="200"
                          max-width="200"
                          class="mx-auto"
                          :src="`http://${doc.url}`"
                        >
                          <v-icon v-if="hover" large color="white">mdi-magnify-plus-outline</v-icon>
                        </v-img>
                      </v-hover>
                    </v-card-text>
                    <v-card-actions class="text-center justify-center">
                      <v-btn text @click="downloadFile(doc.path, doc.nameDocument, doc.extension)" download outlined>
                        <v-icon left>mdi-download</v-icon>Télécharger
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-card outlined v-else>
                    <v-card-text class="EoleError--text text-center">
                      <v-icon class="mr-2 EoleError--text">mdi-alert-circle</v-icon>Document not found
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col class="flex d-flex flex-column">
                  <v-card
                    outlined
                    link
                    class="drop-zone flex d-flex flex-column justify-center text-center"
                    @click="dialogUpload = !dialogUpload"
                  >
                    <v-card-text class="EoleBlue--text">
                      <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                    </v-card-text>
                    <v-card-text><small>Extension de fichier accepté : .pdf</small> </v-card-text>
                    <v-card-text class="EoleBlue--text"> Importer document </v-card-text>
                  </v-card>

                  <v-dialog v-model="dialogUpload" max-width="800px" persistent>
                    <v-row>
                      <v-col cols="12" v-if="document.data != null">
                        <v-card v-if="!!document.data">
                          <v-card-title>
                            <span class="text-h5">Ajout d'un document à la GED de {{ item.nameInterlocutor }}</span>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-row class="pa-5">
                            <v-col cols="12" md="5">
                              <v-text-field
                                @input="replaceSpacesWithUnderscores"
                                outlined
                                label="Nom du document"
                                v-model="document.name"
                                :value="document.data.name"
                              >
                              </v-text-field>
                              <v-select
                                label="Catégorie du document"
                                :items="['Personnel', 'Diplomes', 'Documents contractuel']"
                                outlined
                                v-model="document.categorie"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="1">
                              <v-divider vertical></v-divider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-img
                                v-if="document.data.type === 'image\/jpeg' || document.data.type === 'image\/png'"
                                contain
                                class="mx-auto"
                                :src="fileUrl"
                              >
                              </v-img>

                              <iframe
                                v-else-if="document.data.type === 'application\/pdf'"
                                id="lddcVisualisator"
                                style="width: 100%; height: 500px"
                                :src="fileUrl"
                              >
                              </iframe>

                              <v-card-text class="text-center mt-2">
                                {{ document.data.name }}
                              </v-card-text>
                            </v-col>
                          </v-row>
                          <v-card-actions class="pa-5">
                            <v-btn text class="EoleYellow--text" @click="annulDrop">Annuler </v-btn>
                            <v-spacer></v-spacer>

                            <v-btn
                              class="white--text"
                              color="EoleGreen"
                              @click="uploadPDF(item.id, item.nameInterlocutor)"
                            >
                              <v-icon left>mdi-file-plus</v-icon>Ajouter le document
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col v-else>
                        <v-card>
                          <v-card-title
                            ><span class="text-h5"
                              >Ajout d'un document manquant à la GED de {{ item.nameInterlocutor }}</span
                            ></v-card-title
                          >
                          <v-card
                            outlined
                            class="drop-zone flex d-flex flex-column justify-center text-center mr-5 ml-5"
                            @dragover.prevent
                            @drop="onDrop"
                            v-model="document.data"
                          >
                            <v-card-text class="EoleBlue--text">
                              <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                            </v-card-text>
                            <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                            <v-card-text class="EoleBlue--text"> .pdf / .png / .jpg </v-card-text>
                            <v-card-text class="EoleBlue--text"> Glissez et déposez un fichier ici</v-card-text>
                          </v-card>
                          <v-card-actions class="pa-5">
                            <v-btn text class="EoleYellow--text" @click="dialogUpload = false"> Annuler</v-btn>
                            <v-spacer></v-spacer>

                            <v-btn class="white--text" color="EoleGreen">
                              <v-icon left>mdi-file-plus</v-icon>Ajouter le document
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import Vue from "vue"

import { defineComponent } from "@vue/composition-api"

import config from "@/views/config/config"
import imageViewer from "vue-image-viewer"

import FileSaver from "file-saver"

Vue.use(imageViewer)
const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default defineComponent({
  beforeMount() {
    this.fetchInterlocutor()
    this.fetchNameCutomer()
  },

  props: ["customerId"],

  mounted() {
    this.$watch("customerId")
  },

  data: () => ({
    document: {
      name: null,
      data: null,
    },

    search: "",
    dialogDocument: {},
    idDocument: {},
    dialogUpload: false,
    documentSelect: null,

    expanded: [],
    singleExpand: true,

    headers: [
      {
        text: "Nom client",
        value: "nameInterlocutor",
      },
    ],

    customers: [],
    dialogAddcustomer: false,
    namecustomer: "",
  }),

  computed: {
    formTitle() {
      return "New Item"
    },
  },

  setup() {},

  methods: {
    replaceSpacesWithUnderscores() {
      this.document.name = this.document.name.replace(/ /g, "_")
    },

    annulDrop() {
      this.document = {
        name: null,
        data: null,
      }
      this.documentSelect = false
      this.dialogUpload = false
    },

    async onDrop(event) {
      this.documentSelect = true
      event.preventDefault()
      this.document.data = event.dataTransfer.files[0]
      console.log(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(`Votre fichier est trop volumineux. \n Veuillez choisir un fichier de moins de 1 Mo.`, {
          position: "bottom-right",
          timeout: 3000,
        })
        this.document = {
          name: null,
          data: null,
        }
      } else {
        switch (this.document.data.type) {
          case "application/pdf":
            this.fileSelected = true
            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          case "image/png":
          case "image/jpeg":
            this.fileSelected = true
            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },

    async uploadPDF(idInterlocutor, nameInterlocutor) {
      let agencyId = localStorage.getItem("agency_id")
      let agencyName = localStorage.getItem("agency")

      console.log(" idcustomer : ", this.customerId, " Agance : ", agencyId)
      console.log("-> ", this.document.name, this.document.data, this.customerId, idInterlocutor)
      console.log("-> chemin : ", this.document.data["tmp_name"])

      const formData = new FormData()
      formData.append("idInterlocutor", idInterlocutor)
      formData.append("customerId", this.customerId)
      formData.append("agencyId", agencyId)
      formData.append("nameInterlocutor", nameInterlocutor.toLowerCase().replace(" ", "_"))
      formData.append("agencyName", agencyName.toUpperCase().replace(" ", "_"))
      formData.append("document", this.document.data)
      formData.append("documentName", this.document.name)
      formData.append("namecustomer", this.namecustomer.replace(" ", "_"))

      try {
        const res = await fetch(`${config.apiUri}/customer_file`, {
          method: "POST",
          body: formData,
        })
        console.log(res.ok, this.document.name)
        if (res.ok) {
          this.dialogUpload = false
          console.log("ok 2")
          this.document = {
            name: "",
            data: null,
          }
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (e) {
        console.error(e)
      }
      this.fetchInterlocutor()
    },

    async fetchInterlocutor() {
      let agencyId = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customerId}/customer_interlocutor`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        const data = await response.json()

        this.customers = []
        data.Interlocutor.forEach(element => {
          this.customers.push({
            id: element.id,
            nameInterlocutor: element.name,
          })
        })

        this.$toast.info(`Affichage des Interlocuteurs.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      } catch (error) {
        this.$toast.error(`Impossible de charger les éléments interlocuteurs.`, {
          position: "bottom-right",
          timeout: 5000,
        })
        console.log(error)
      }
      console.log("-> ", this.customers)

      let index = 0
      this.customers.forEach(elem => {
        this.fetchFilecustomer(elem.id, index)
        index = index + 1
      })
      console.log("aff final - > ", this.customers)
    },

    async fetchFilecustomer(idInterloc, index) {
      let agencyId = localStorage.getItem("agency_id")
      let listDocument = []

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customerId}/customer_file/${idInterloc}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        const data = await response.json()

        data.forEach(element => {
          listDocument.push({
            id: element.id,
            nameDocument: element.name,
            extension: element.extension,
            path: element.path,
            url: element.url,
          })
        })
        console.log(listDocument)
      } catch (e) {
        console.log(e)
      }

      this.customers[index]["document"] = listDocument
    },

    async fetchNameCutomer() {
      let agencyId = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customerId}/customer_interlocutor`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        const data = await response.json()
        // this.namecustomer = data[0].name_customer
        console.log("nameCusto : ", data)
      } catch (e) {
        console.log(e)
      }
    },

    async downloadFile(path, documentName, extension) {
      let blobConstruct = null
      let headers = new Headers()

      headers.append("Content-Type", "image/jpeg")
      headers.append("Accept", "image/jpeg")
      headers.append("Origin", "*")

      try {
        const response = await fetch(`${config.apiUri}/php/pages/download.php?path=${path}`, {
          method: "GET",
          headers: headers,
        })

        blobConstruct = await response.blob()

        FileSaver.saveAs(blobConstruct, `${documentName}.${extension}`)
      } catch (e) {
        console.log(e)
      }
    },
  },
})
</script>
<style>
.drop-zone {
  /* styles de base */
}

.drop-zone:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone-error {
  /* styles de base */
}

.drop-zone-error:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #c1272d;
}

.drop-zone-error.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}
.v-treeview:hover {
  cursor: pointer;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.highlighted:hover:before {
  visibility: visible;
}
</style>
