<template>
  <v-container fluid class="no-padding">
    <v-row class="my-12 d-flex"> 
      <v-col cols="12" md="4">
        <v-card-title class="no-padding">Conformité client</v-card-title>
        <v-divider></v-divider>
        <v-card outlined elevation="3" class="pa-5 my-5">
          <v-row>
            <v-col class="d-flex" v-for="admin in followAdmin" :key="admin.id" cols="12" md="4">
              <v-card class="mx-auto" width="100%" flat>
                <v-card-text class="text-center">
                  <v-progress-circular size="64" width="7" :value="`${admin.progressPourcentage}`" rotate="270"
                    :color="admin.progressPourcentage < 100 ? '#CE616B' : '#4A919E' ">
                    16
                  </v-progress-circular>
                </v-card-text>
                <v-card-text class="text-center">{{ admin.progressTitle }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex" v-for="admin in followTechnical" :key="admin.id" cols="12" md="4">
              <v-card class="mx-auto" width="100%" flat>
                <v-card-text class="text-center">
                  <v-progress-circular size="64" width="7" :value="`${admin.progressPourcentage}`" rotate="270"
                    :color="admin.progressPourcentage < 100 ? '#CE616B' : '#4A919E'">
                    56
                  </v-progress-circular>
                </v-card-text>
                <v-card-text class="text-center">{{ admin.progressTitle }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex" v-for="admin in followCommercial" :key="admin.id" cols="12" md="4">
              <v-card class="mx-auto" width="100%" flat>
                <v-card-text class="text-center">
                  <v-progress-circular size="64" width="7" :value="`${admin.progressPourcentage}`" rotate="270"
                    :color="admin.progressPourcentage < 100 ? '#CE616B' : '#4A919E'">
                    100
                  </v-progress-circular>
                </v-card-text>
                <v-card-text class="text-center">{{ admin.progressTitle }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-if="this.customerTypology === 'professionnel'" cols="12" md="8">
        <v-card-title class="no-padding">Informations clés</v-card-title>
        <v-divider></v-divider>
        <v-row class="justify-left no-padding my-2">
          <v-col class="d-flex" v-for="keyP in keyNumber" :key="keyP.id" cols="12" md="3">
            <v-card class="mx-auto" width="100%" outlined elevation="3">
              <v-card-text>
                <h2 class="text-center">{{ keyP.keyTitle }}</h2>
                <p class="text-h7 text-center" style="color: #fcc03c">{{ keyP.keyPrincipal }}
                </p>
                <p class="text-h7 text--primary text-center">
                  {{ keyP.description }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="this.customerTypology === 'particulier'">
        <v-card outlined elevation="3" width="900">
          <v-card-title class="transparent" style="font-size: 1.5rem">Ma Famille</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-center my-5"><b>Vous n'avez pas de Famille</b></v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="this.customerTypology === 'professionnel'" width="1500" class="my-10">
      <v-card-title class="no-padding">Mon groupe</v-card-title>
      <v-divider></v-divider>
      <v-row class="my-2">
        <v-col cols="12" md="4" v-for="etablishment in etablishments"
          v-bind:key="etablishment.id" class="d-flex">
          <v-card v-if="etablishment.active" outlined elevation="3" :color="etablishment.active ? '' : 'rgba(193,39,44,0.3)'">
            <v-card-text class="text-center">
              <v-icon size="64">mdi-office-building</v-icon>
            </v-card-text>
            <v-card-text class="EoleError--text text-center" v-if="!etablishment.active">
              <v-chip small color="rgba(193,39,44,0.6)">
                Fermé depuis le {{ etablishment.closeDate }}
              </v-chip>
            </v-card-text>
            <v-card-text class="EoleGreen--text text-center" v-else>
              <v-icon color="EoleGreen">mdi-check-circle-outline</v-icon>
              Établissement en activité
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4"><strong>Nom de l'entité : </strong> </v-col>
                <v-col cols="12" md="8">
                  {{ etablishment.name }}
                </v-col>
                <v-col cols="12" md="4"><strong>Adresse : </strong> </v-col>
                <v-col cols="12" md="8">
                  <a
                    @click="showMap(`${etablishment.address} ${etablishment.zipCode} ${etablishment.city}`)">
                    {{ etablishment.address }} <br />
                    {{ etablishment.zipCode }}, {{ etablishment.city }}
                  </a>
                </v-col>
                <v-col cols="12" md="4"><strong>Siret :</strong> </v-col>
                <v-col cols="12" md="8">{{ etablishment.siret }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    

    <div v-if="this.customerTypology === 'professionnel'" class="my-15">
      <v-card-title class="no-padding">Mes interlocuteurs C2T ASSURANCES</v-card-title>
      <v-divider></v-divider>
      <v-row class="my-2">
        <v-col class="d-flex" v-for="inter in interlocutors" :key="inter.id" cols="12" md="3">
          <v-card max-width="244" outlined elevation="3">
            <v-card-text>
              <h2 class="text-center">{{ inter.name }}</h2>
              <p class="text-center">
                <v-icon left>mdi-email</v-icon><a :href="`mailto:${inter.mail}`">{{ inter.mail }}</a>
              </p>
              <p class="text--primary text-center">{{ inter.phone }}</p>
              <p class="text-center" style="color: #1697f6">{{ inter.role }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <br />
    </div>
  </v-container>
</template>

<script>
  import Vue from "vue"
  import Toast from "vue-toastification"
  // Import the CSS or use your own!
  import "vue-toastification/dist/index.css"
  import {
    init
  } from "@emailjs/browser"
  init("user_gUunL4KW2ImPaGngWHYhP")
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css"
  import VerticalNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue"
  import config from "@/views/config/config"

  Vue.use(Toast, {
    maxToasts: 2,
    newestOnTop: true
  })

  export default {
    
  
    props: ["customerId", "customerTypology"],

    mounted() {
      this.$watch("customerId", this.fetchData())
    },

    components: {
      VerticalNavMenu
    },
    data: () => ({
      upHere: false,

      agencyName: null,
      load: false,
      ipError: false,
      ip: "",
      ipLocation: "",
      ipInfo: [],
      states: ["administrateur", "collaborateur"],

      beneficialOwner: [],

      interlocutors: [{
          id: 1,
          img: "roman.png",
          name: "FRICHOT ROMAN",
          mail: "roman.frichot@eole-assurance.bzh",
          phone: "02.99.54.XX.XX",
          role: "MASTER CYBER SECURITÉ",
        },
        {
          id: 2,
          img: "roro.jpg",
          name: "MATHIS GRANNEC",
          mail: "mathis.grannec@eole-assurance.bzh",
          phone: "02.98.16.XX.XX",
          role: "BSI CYBER SECURITÉ",
        },
        {
          id: 3,
          img: "roro.jpg",
          name: "DAVID AUDREN",
          mail: "david.audren@eole-assurance.bzh",
          phone: "02.98.48.XX.XX",
          role: "MANAGER",
        },
      ],
      
      etablishments: [{
          id: 0,
          name: "Éole Assurance",
          address: "1 AV DES PRUNUS",
          city: "COMBOURG",
          zipCode: "35270",
          siret: "12345678912345",
          active: true,
        },
        {
          id: 1,
          name: "Éole Assurance",
          address: "1 AV DU 41E REG D INFANTERIE",
          city: "RENNES",
          zipCode: "35000",
          siret: "12345678912345",
          active: true,
        }
      ],
      
      certificate: [{
          id: 1,
          certif: "XX",
          name: "Attestation Responsabilité civile",
        },
        {
          id: 2,
          certif: "XX",
          name: "Attestation décennale",
        },
        {
          id: 3,
          certif: "XX",
          name: "Attestation Dommages aux biens",
        },
      ],
      keyNumber: [
        {
          id: 1,
          keyTitle: "103 125,13 €",
          keyPrincipal: "+ 2,5 %",
          description: "Primes",
        },
        {
          id: 2,
          keyTitle: "15 213,12 €",
          keyPrincipal: "+ 2,8 %",
          description: "Commissions",
        },
        {
          id: 3,
          keyTitle: "0",
          keyPrincipal: "-2",
          description: "Sinistres",
        },
        {
          id: 4,
          keyTitle: "28 %",
          keyPrincipal: "- 15 %",
          description: "Sinistres / Primes",
        },
        {
          id: 5,
          keyTitle: "10",
          keyPrincipal: "",
          description: "Contrats",
        },
        
        {
          id: 6,
          keyTitle: "2022",
          keyPrincipal: "18 213 000,56" + "€",
          description: "Chiffre d'affaires",
        },
        {
          id: 7,
          keyTitle: "27",
          keyPrincipal: "+2",
          description: "Collaborateurs",
        },
        {
          id: 8,
          keyTitle: "0",
          keyPrincipal: "",
          description: "Réclamations",
        },
        {
          id: 9,
          keyTitle: "61",
          keyPrincipal: "6 213 000,56" + "€",
          description: "Les franchisés",
        },
        {
          id: 10,
          keyTitle: "98",
          keyPrincipal: "5 213 000,56" + "€",
          description: "Les installateurs",
        },
        
        {
          id: 11,
          keyTitle: "2023",
          keyPrincipal: "20 213 000,56" + "€",
          description: "Projection C.A",
        },
      ],
      // infoProgress2: [{
      //     id: 1,
      //     progressTitle: "Etab.",
      //     progressPourcentage: 100,
      //     progressInfo: [],
      //   },
      //   {
      //     id: 2,
      //     progressTitle: "Interloc.",
      //     progressPourcentage: 100,
      //     progressInfo: [],
      //   },
      //   {
      //     id: 3,
      //     progressTitle: "Contrats",
      //     progressPourcentage: 75,
      //     progressInfo: ["Numéro téléphone du client"],
      //   },
      //   {
      //     id: 4,
      //     progressTitle: "Sinistres",
      //     progressPourcentage: 60,
      //     progressInfo: ["Chèque de caution"],
      //   },
      //   {
      //     id: 5,
      //     progressTitle: "Tâches",
      //     progressPourcentage: 50,
      //     progressInfo: ["Acheter du pain"],
      //   },
      // ],
      
      followAdmin: [
        {
          id: 1,
          progressTitle: "Interloc.",
          progressPourcentage: 100,
          progressInfo: [],
        },
        {
          id: 2,
          progressTitle: "Contrats",
          progressPourcentage: 75,
          progressInfo: ["Numéro téléphone du client"],
        },
        {
          id: 3,
          progressTitle: "Sinistres",
          progressPourcentage: 60,
          progressInfo: ["Chèque de caution"],
        },
      ],

      followTechnical: [
        {
          id: 1,
          progressTitle: "Q4",
          progressPourcentage: 100,
          progressInfo: [],
        },
        {
          id: 2,
          progressTitle: "Q18",
          progressPourcentage: 75,
          progressInfo: ["Numéro téléphone du client"],
        },
        {
          id: 3,
          progressTitle: "Q19",
          progressPourcentage: 60,
          progressInfo: ["Chèque de caution"],
        },
      ],

      followCommercial: [
        {
          id: 1,
          progressTitle: "Tâche",
          progressPourcentage: 100,
          progressInfo: [],
        },
        {
          id: 2,
          progressTitle: "Devis",
          progressPourcentage: 75,
          progressInfo: ["Numéro téléphone du client"],
        },
        {
          id: 3,
          progressTitle: "Avenants",
          progressPourcentage: 60,
          progressInfo: ["Chèque de caution"],
        },
      ],
    }),

    methods: {

      // async fetchBeneficialOwner() {
      //   let agencyId = localStorage.getItem("agency_id")
      //   // console.log("nom interloc : ", this.customerId)
      //   try {
      //     let headers = new Headers()

      //     headers.append("Content-Type", "application/json")
      //     headers.append("Accept", "application/json")
      //     headers.append("Origin", "*")

      //     const response = await fetch(`${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customerId}/customer_interlocutor`, {
      //       mode: "cors",
      //       method: "GET",
      //       headers: headers,
      //     })
      //     const data = await response.json()

      //     this.beneficialOwner = []
      //     data.Interlocutor.forEach(element => {
      //       console.log("be -> ", element.beneficial_owner[0])
      //       if (element.beneficial_owner[0] != undefined){
      //         this.beneficialOwner.push({
      //           nameBeneficialOwner: element.beneficial_owner[0].name,
      //           addressBeneficialOwner: element.beneficial_owner[0].address,
      //           postalCodeBeneficialOwner: element.beneficial_owner[0].postal_code,
      //           cityBeneficialOwner: element.beneficial_owner[0].city,
      //           bornCityBeneficialOwner: element.beneficial_owner[0].born_city,
      //           bornCountryBeneficialOwner: element.beneficial_owner[0].country_born,
      //           nationalityBeneficialOwner: element.beneficial_owner[0].nationality,
      //         })              
      //       }
      //     })
      //     // console.log("value -> ", this.beneficialOwner)

      //   } catch (error) {
      //     this.$toast.error(`Impossible de charger les éléments interlocuteurs.`, {
      //       position: "bottom-right",
      //       timeout: 5000,
      //     })
      //     console.log(error)
      //   }
      // },

      fetchData(){
        console.log("Page Home -> id customer : ", this.customerId)
      }


    },
  }

</script>