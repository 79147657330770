<template>
  <div>
    <v-row align="center" class="justify-center">
      <v-col cols="12" md="10" xl="10">
        <v-card outlined width="1500">
          <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
            <v-row class="d-flex align-center">
              <v-col cols="12" md="7">Mes clients</v-col>
              <v-col cols="12" md="5">
                <v-text-field v-model="search" class="text-white" color="white"
                  append-icon="mdi-magnify" label="Recherche" single-line hide-details>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table v-model="selected" :headers="headers" :items="customers" hide-default-footer
            :single-select="singleSelect" :search="search" item-key="name" :page.sync="page"
            @page-count="pageCount = $event">
            <template v-slot:top>
              <v-dialog v-model="dialogUpdateCustomer" persistent max-width="600px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">
                      <v-icon>mdi-pencil</v-icon> Modification du client
                    </span>
                  </v-card-title>
                  <v-divider></v-divider>

                  <v-card-text>
                    <v-container>
                      <v-text-field v-model="updateCustomer.name" label="Nom / Raison Sociale" v-if="updateCustomer.segment === 'professionnel'"></v-text-field>
                      <v-text-field v-model="updateCustomer.lastName" label="Nom" v-if="updateCustomer.segment === 'particulier'"></v-text-field>
                      <v-text-field v-model="updateCustomer.firstName" label="Prénom" v-if="updateCustomer.segment === 'particulier'"></v-text-field>

                      <v-text-field v-model="updateCustomer.mail" label="Mail"></v-text-field>
                      <v-text-field v-model="updateCustomer.phone" label="Téléphone"></v-text-field>
                      <v-text-field v-model="updateCustomer.address" label="Adresse"></v-text-field>
                      <v-text-field v-model="updateCustomer.postalCode" label="Code postal"></v-text-field>
                      <v-text-field v-model="updateCustomer.city" label="Ville"></v-text-field>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="EoleYellow" text @click="dialogUpdateCustomer = false">
                      Annuler</v-btn>
                    <v-btn class="white--text" color="EoleBlue" @click="putUpdateCustomer">
                      Confirmer la Modification
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <v-card-text class="no-padding" @click="rowClick(item)">{{ item.name }}</v-card-text>
            </template>
            <template v-slot:[`item.mail`]="{ item }">
              <v-card-text class="no-padding" @click="rowClick(item)">{{ item.mail }}</v-card-text>
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              <v-card-text class="no-padding" @click="rowClick(item)">{{ item.phone }}</v-card-text>
            </template>
            <template v-slot:[`item.address`]="{ item }">
              <v-card-text class="no-padding" @click="rowClick(item)">{{ item.address }}</v-card-text>
            </template>
            <template v-slot:[`item.segment`]="{ item }">
              <v-card-text class="no-padding" @click="rowClick(item)">{{ item.segment }}</v-card-text>
            </template>
            <template v-slot:[`item.typology`]="{ item }">
              <v-card-text class="no-padding" @click="rowClick(item)">{{ item.typology }}</v-card-text>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text icon @click="updateItemCustomer(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifer le "{{ item.name }}"</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" xl="3">
        <v-card v-on:click="pageAddCustomer" class="justify-center text-center" link outlined
          width="400" height="300">
          <v-row align="center" class="fill-height">
            <v-col cols="12" md="12" outlined>
              <v-card-text>
                <i class="fa-solid fa-user-plus fa-8x EoleBlue--text"></i>
                <h2 class="mt-2 EoleBlue--text">Ajouter Client</h2>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import config from "@/views/config/config"
  import {
    defineComponent
  } from "@vue/composition-api"

  export default defineComponent({
    beforeMount() {
      this.fetchListCustomerParticular()
    },

    data: () => ({
      singleSelect: true,
      selected: [],
      search: "",
      selectCustomer: 0,
      selectTypoCustomer: "",
      selectFranchise: null,

      page: 1,
      pageCount: 0,

      dialogUpdateCustomer: false,
      updateCustomer: {
        id: null,
        name: null,
        mail: null,
        phone: null,
        address: null,
        postalCode: null,
        city: null,
        segment: null,
      },

      dialogDeleteCustomer: false,
      idDeleteCustomer: {
        id: null,
        segment: null,
      },

      headers: [{
          text: "Nom / Raison sociale",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Mail",
          value: "mail",
        },
        {
          text: "Téléphone",
          value: "phone",
        },
        {
          text: "Adresse",
          value: "address",
        },
        // {
        //   text: "Interlocuteur principal",
        //   value: "interloc"
        // },
        {
          text: "Segment",
          value: "segment",
        },
        {
          text: "Typologie",
          value: "typology",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],

      customers: [],
      dialogAddCustomer: false,

      hovered: null,
    }),

    computed: {
      formTitle() {
        return "New Item"
      },
    },

    setup() {},

    methods: {
      pageAddCustomer() {
        this.$emit("addNewCustomer")
      },

      showPageCustomer() {
        if (this.selectCustomer != 0) {
          this.$emit("showPageCustomer", this.selectCustomer, this.selectTypoCustomer, this.selectFranchise)
        } else {
          this.$toast.error(`Vous devez selectionner un client pour continuer.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      },

      rowClick(item) {
        try {
          this.selectCustomer = item.id
          this.selectTypoCustomer = item.segment
          this.selectFranchise = item.franchise
          this.showPageCustomer()
        } catch (error) {
          this.selectCustomer = 0
        }
      },

      updateItemCustomer(item){
        console.log("item : ", item)
        this.updateCustomer = {
          id: null,
          name: null,
          mail: null,
          phone: null,
          address: null,
          postalCode: null,
          city: null,
          segment: null,
          lastName: null,
          firstName: null,
        },
        this.updateCustomer = {
          id: item.id,
          name: item.name,
          mail: item.mail,
          phone: item.phone,
          address: item.address,
          postalCode: item.postalCode,
          city: item.city,
          segment: item.segment,
          lastName: item.lastName,
          firstName: item.firstName,
        }
        this.dialogUpdateCustomer = true
      },

      async putUpdateCustomer() {
        console.log("update custo : ", this.updateCustomer)
        let agencyId = localStorage.getItem("agency_id")
        console.log("agency : ", agencyId)

        if (this.updateCustomer.segment === "professionnel") {
          console.log("good")
          let changeInfoCustomerPro = "updateCustomerPro"
          try {
            let headers = new Headers()

            headers.append("Content-Type", "application/json charset=UTF-8")
            headers.append("Accept", "application/json")
            headers.append("Origin", "*")

            let response = await fetch(
              `${config.apiUri}/php/pages/customer_professional.php?idAgency=${agencyId}&idProfessional=${this.updateCustomer.id}&nameCustomer=${this.updateCustomer.name}
              &mail=${this.updateCustomer.mail}&phone=${this.updateCustomer.phone}&address=${this.updateCustomer.address}&postalCode=${this.updateCustomer.postalCode}
              &city=${this.updateCustomer.city}&place=${changeInfoCustomerPro}`, {
                mode: "cors",
                method: "PUT",
                headers: headers,
              },
            )
            this.$toast.success(`Vous avez modifier le client ${this.updateCustomer.name}`, {
              position: "bottom-right",
              timeout: 3000,
            })
          } catch (e) {
            console.log(e)
            this.$toast.error(
              `Une erreur est survenue.\nImpossible de modifier le client ${this.updateCustomer.name}`, {
                position: "bottom-right",
                timeout: 3000,
              })
          }
        } else if (this.updateCustomer.segment === "particulier") {
          try {
            let headers = new Headers()

            headers.append("Content-Type", "application/json charset=UTF-8")
            headers.append("Accept", "application/json")
            headers.append("Origin", "*")

            let response = await fetch(
              `${config.apiUri}/php/pages/customer_particular.php?idAgency=${agencyId}&idParticular=${this.updateCustomer.id}&lastName=${this.updateCustomer.lastName}&firstName=${this.updateCustomer.firstName}
              &mail=${this.updateCustomer.mail}&phone=${this.updateCustomer.phone}&address=${this.updateCustomer.address}&postalCode=${this.updateCustomer.postalCode}
              &city=${this.updateCustomer.city}`, {
                mode: "cors",
                method: "PUT",
                headers: headers,
              },
            )
            this.$toast.success(`Vous avez modifier le client ${this.updateCustomer.name}`, {
              position: "bottom-right",
              timeout: 3000,
            })
          } catch (e) {
            console.log(e)
            this.$toast.error(
              `Une erreur est survenue.\nImpossible de modifier le client ${this.updateCustomer.name}`, {
                position: "bottom-right",
                timeout: 3000,
              })
          }
        }
        this.dialogUpdateCustomer = false
        this.fetchListCustomerParticular()
      },

      async fetchListCustomerParticular() {
        let agencyId = localStorage.getItem("agency_id")

        try {
          let headers = new Headers()

          headers.append("Content-Type", "application/json")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")

          const response = await fetch(
            `${config.apiUri}/agencies/${agencyId}/customer_particular`, {
              mode: "cors",
              method: "GET",
              headers: headers,
            })
          const data = await response.json()

          this.customers = []
          data.forEach(element => {
            this.customers.push({
              id: element.id,
              name: element.lastname + " " + element.firstname,
              lastName: element.lastname,
              firstName: element.firstname,
              mail: element.email,
              phone: element.phone,
              address: element.address,
              postalCode: element.postal_code,
              city: element.city,
              segment: element.typology,
              typology: "Client ou Prospect",
              hovered: false,
            })
          })

          // this.$toast.info(`Affichage de la liste des clients.`, {
          //   position: "bottom-right",
          //   timeout: 5000,
          // })
        } catch (e) {
          this.$toast.error(`Impossible de charger la liste des client particulier.`, {
            position: "bottom-right",
            timeout: 5000,
          })
          console.log(e)
        }
        this.fetchListCustomerProfessional()
      },

      async fetchListCustomerProfessional() {
        let agencyId = localStorage.getItem("agency_id")

        try {
          let headers = new Headers()

          headers.append("Content-Type", "application/json")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")

          const response = await fetch(
            `${config.apiUri}/agencies/${agencyId}/customer_professional`, {
              mode: "cors",
              method: "GET",
              headers: headers,
            })
          const data = await response.json()

          data.forEach(element => {
            this.customers.push({
              id: element.id,
              name: element.commercial_name,
              mail: element.email,
              phone: element.phone,
              address: element.address,
              postalCode: element.postal_code,
              city: element.city,
              segment: element.segment,
              typology: element.typology,
              hovered: false,
              franchise: element.franchise,
            })
          })

          // this.$toast.info(`Affichage de la liste des clients.`, {
          //   position: "bottom-right",
          //   timeout: 5000,
          // })
        } catch (e) {
          this.$toast.error(`Impossible de charger la liste des client professionnel.`, {
            position: "bottom-right",
            timeout: 5000,
          })
          console.log(e)
        }
      },
    },
  })

</script>

<style scoped>
  .v-data-table>>>tr:hover {
    cursor: pointer;
    background: rgba(252, 192, 60, 0.3) !important;
  }

  ::v-deep .text-white input {
    color: white;
    background-color: transparent;
  }

  ::v-deep .text-white .v-label {
    color: white;
  }

  ::v-deep .text-white .v-icon.mdi-magnify {
    color: white;
  }

  /* Pas réussis à mettre la barre présent sous le Label ' Recherche ' en blanc. C'est galère de fouuuu. */

</style>
