<template>
  <v-container fluid class="no-padding">
    <v-tabs v-model="tabChooseCateSupplier" class="mt-12" style="box-shadow: none !important"
      background-color="transparent">
      <v-tab>Mes Franchises</v-tab>
      <v-tab>Mes Installateurs</v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="tabChooseCateSupplier" style="background-color: transparent">
      <v-tab-item>
        <v-row class="mb-5 text-center my-5">
          <v-col class="d-flex" cols="12" md="4">
            <v-card class="flex d-flex flex-column" link outlined>
              <v-card-title>Franchises actives</v-card-title>
              <v-row v-if="itemsFranchise.length > 0" align="center">
                <v-card-text>
                  <v-icon size="48" color="EoleGreen">mdi-check-circle</v-icon>
                  <h2>{{ itemsFranchise.length }}</h2>
                </v-card-text>
              </v-row>
              <v-row v-else align="center">
                <v-card-text>
                  <p>Vous n'avez pour le moment pas de franchise. Pour en ajouter, cliquez sur le
                    bouton ci-dessous.</p>
                </v-card-text>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="d-flex" cols="12" md="4">
            <v-card class="flex d-flex flex-column" link outlined>
              <v-card-title>Franchises inactives</v-card-title>
              <v-row v-if="itemsFranchiseDisapproved.length > 0" align="center">
                <v-card-text>
                  <v-icon size="48" color="EoleYellow">mdi-minus-circle</v-icon>
                  <h2>{{ itemsFranchiseDisapproved.length }}</h2>
                </v-card-text>
              </v-row>
              <v-row v-else align="center">
                <v-card-text>
                  <p>Il n'y a pas de partenaires non approuvés pour le moment.</p>
                </v-card-text>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="my-5">
          <v-col cols="12" md="4">
            <v-card flat outlined>
              <v-card-title class="EoleBlueLighten">
                <v-col cols="12" md="10">Liste des franchises</v-col>
                <v-col cols="12" md="2">
                  <v-dialog v-model="dialogAddFranchise" max-width="1100px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="EoleBlue" outlined v-bind="attrs" v-on="on" large>
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <v-card-title>
                      <span class="text-h5">Ajouter Franchise</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card outlined width="1089">
                      <v-card-title>
                        <v-icon class="mr-2">mdi-account</v-icon> Renseignement de société
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="pa-5">
                        <v-text-field :error="siretNotFound" :error-messages="siretNotFoundMessage" type="number"
                          counter="14" outlined v-model="siret" label="Entrez votre n° de siret"
                          prepend-inner-icon="mdi-briefcase">
                        </v-text-field>
                      </v-card-text>
                      <v-row>
                        <v-col cols="12" md="2" class="text-left">
                          <v-btn text class="EoleYellow--text ml-5" @click="dialogAddFranchise = false" v-if="!dataPappers">Annuler</v-btn>
                        </v-col>
                        <v-col v-if="!dataPappers" class="text-right" cols="12" md="8">
                          <v-btn outlined @click="dataPappers = true">
                            <v-icon class="mr-3">mdi-information</v-icon>Faire une saisie classique
                          </v-btn>
                        </v-col>
                        <v-col class="text-center" cols="12" md="2" v-if="!dataPappers">
                          <v-btn color="primary" @click="fetchPappers(siret)">recherche</v-btn>
                        </v-col>
                      </v-row>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" v-if="dataPappers">
                            <v-row>
                              <v-col cols="12" md="4">
                                <v-text-field outlined v-model="company.nameCompany"
                                  label="Nom de la société" prepend-inner-icon="mdi-domain"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field outlined v-model="company.phone"
                                  label="Numéro de téléphone" prepend-inner-icon="mdi-phone"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field outlined v-model="company.email" label="Email"
                                  prepend-inner-icon="mdi-email"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field outlined v-model="company.postalCode"
                                  label="Code Postale" prepend-inner-icon="mdi-map-marker"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field outlined v-model="company.address"
                                  label="Addresse" prepend-inner-icon="mdi-map-marker"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field outlined v-model="company.city" label="Ville"
                                  prepend-inner-icon="mdi-map-marker"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-card-title v-if="dataPappers">
                        <v-icon class="mr-2">mdi-information</v-icon> Description de l'activité de
                        {{ company.nameCompany }}
                      </v-card-title>
                      <v-divider v-if="dataPappers"></v-divider>

                      <v-card-text v-if="dataPappers" class="pa-5">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field outlined v-model="company.codeNaf"
                              label="Code NAF/APE" prepend-inner-icon="mdi-briefcase"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field outlined v-model="company.libelleNaf"
                              label="Description de l'activité" prepend-inner-icon="mdi-briefcase">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-card-title v-if="dataPappers">
                        <v-icon class="mr-2">mdi-account-tie</v-icon> A propos des dirigeants
                      </v-card-title>
                      <v-divider v-if="dataPappers"></v-divider>
                      <v-card-text v-if="dataPappers" class="no-padding">
                        <v-data-table v-model="signatory" :headers="headersDirectors"
                          :items="company.representants"  hide-default-footer no-data-text="Aucun dirigeant ajouté. (Cliquez sur le bouton ci-dessous pour ajouter un dirigeant à la liste)">
                          <template v-slot:[`item.checkbox`]="{ item }">
                            <v-checkbox :disabled="item.nom === '' || item.prenom_usuel === ''" :color="getCheckboxColor()" v-model="signatory" :value="{
                              civility: item.sexe === 'M' ? 'M' : 'Mme', name: item.nom + ' ' + item.prenom_usuel, }" :key="item.nom">
                            </v-checkbox>
                          </template>
                          <template v-slot:[`item.sexe`]="{ item }">
                            <v-select class="mt-5" dense :value="item.sexe" :items="['M', 'Mme']"
                              prepend-inner-icon="mdi-account">
                            </v-select>
                          </template>

                          <template v-slot:[`item.nom`]="{ item }">
                            <v-text-field class="mt-5" dense v-model="item.nom" prepend-inner-icon="mdi-account">
                            </v-text-field>
                          </template>
                          <template v-slot:[`item.prenom_usuel`]="{ item }">
                            <v-text-field class="mt-5" dense v-model="item.prenom_usuel"
                              prepend-inner-icon="mdi-account"></v-text-field>
                          </template>
                          <template v-slot:[`item.loisir`]="{ item }">
                            <v-text-field class="mt-5" dense v-model="item.loisir"
                              prepend-inner-icon="mdi-account"></v-text-field>
                          </template>
                        </v-data-table>
                        <v-row class="px-2 my-2">
                          <v-btn block text elevation="0" @click="addDirector">
                            <v-icon left>mdi-plus</v-icon> Ajouter un dirigeant
                          </v-btn>
                        </v-row>
                      </v-card-text>

                      <br />
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text class="EoleYellow--text" @click="dialogAddFranchise = false, dataPappers = false" v-if="dataPappers">Annuler</v-btn>
                        <v-btn @click="addCustomerPro" outlined v-if="dataPappers">
                          <i class="fa-solid fa-user-plus mr-2"></i>
                          <span>Ajouter</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="no-padding">
                <v-treeview open-all :active.sync="activeFranchise" :items="itemsFranchise"
                  :open.sync="open" activatable color="EoleBlue" transition
                  selected-color="EoleError">
                </v-treeview>
                <template v-slot:prepend="{ item }">
                  {{ item.name }}
                </template>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions class="justify-end no-padding align-center mt-4 mr-2 EoleBlue--text">
                <p class="text-caption">{{ itemsFranchise.length }} franchises trouvés</p>
                <br />
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="8">
            <v-scroll-y-transition mode="out-in">
              <v-row v-if="!selectedFranchise" align="center" class="text-left">
                <v-col>
                  <v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense>
                    Veuillez selectionner un partenaire à afficher dans cette fenêtre.</v-alert>
                </v-col>
              </v-row>
              <v-card v-else :key="selectedFranchise.id" class="mx-auto" flat>
                <v-card outlined class="text-center">
                  <v-card-title class="EoleBlueLighten mb-5">
                    <v-row>
                      <v-col class="text-left" cols="12" md="9">{{ selectedFranchise.name }}</v-col>
                      <v-col class="text-right" cols="12" md="3"></v-col>
                    </v-row>
                  </v-card-title>

                  <v-avatar class="avatar bordered" size="128" color="EoleBlue">
                    <div class="hover-overlay">
                      <span class="overlay-text white--text">Changer le logo</span>
                    </div>
                    <i class="fa fa-building fa-3x white--text"></i>
                  </v-avatar>
                  <h3 class="text-h5 mb-2">{{ selectedFranchise.name }}</h3>
                  <h4 class="text-h6 mb-2">
                    {{ selectedFranchise.address }}, {{ selectedFranchise.postalCode }}
                    ({{ selectedFranchise.city }})
                  </h4>
                  <h5 class="text-h6 mb-2">{{ selectedFranchise.naf_libelle }}
                    ({{ selectedFranchise.naf_code }})
                  </h5>
                  <p>N° de siret : {{ selectedFranchise.siret }}</p>
                  <p>{{ selectedFranchise.interlocutor }}</p>
                  <v-card-title v-if="selectedFranchise.interlocutor.length > 0" class="EoleBlueLighten">
                    <v-row>
                      <v-col cols="12" md="12" class="text-left">interlocuteurs de la société {{ selectedFranchise.name }}</v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text v-if="selectedFranchise.interlocutor.length > 0">
                    <v-row class="text-center pa-5">
                      <v-col cols="12" md="3" v-for="interlocutor in selectedFranchise.interlocutor"
                        v-bind:key="interlocutor.id">
                        <v-card width="200" outlined elevation="3"
                          class="rounded-xl align-center d-flex flex-column justify-center">
                          <v-card-text>
                            <v-avatar class="mb-5" size="64" color="EoleBlue">
                              <v-icon size="36" color="EoleBlueLighten">mdi-clipboard-account
                              </v-icon>
                            </v-avatar>
                          </v-card-text>
                          <v-card-text class="align-center text-center">
                            <p>{{ interlocutor.name }}</p>
                            <p>{{ interlocutor.quality }}</p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row class="mb-5 text-center my-5">
          <v-col class="d-flex" cols="12" md="4">
            <v-card class="flex d-flex flex-column" link outlined>
              <v-card-title>Installateurs actifs</v-card-title>
              <v-row v-if="itemsInstall.length > 0" align="center">
                <v-card-text>
                  <v-icon size="48" color="EoleGreen">mdi-check-circle</v-icon>
                  <h2>{{ itemsInstall.length }}</h2>
                </v-card-text>
              </v-row>
              <v-row v-else align="center">
                <v-card-text>
                  <p>Vous n'avez pour le moment pas de franchise. Pour en ajouter, cliquez sur le
                    bouton ci-dessous.</p>
                </v-card-text>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="d-flex" cols="12" md="4">
            <v-card class="flex d-flex flex-column" link outlined>
              <v-card-title>Installateurs inactifs</v-card-title>
              <v-row v-if="itemsInstallDisapproved.length > 0" align="center">
                <v-card-text>
                  <v-icon size="48" color="EoleYellow">mdi-minus-circle</v-icon>
                  <h2>{{ itemsInstallDisapproved.length }}</h2>
                </v-card-text>
              </v-row>
              <v-row v-else align="center">
                <v-card-text>
                  <p>Il n'y a pas de partenaires non approuvés pour le moment.</p>
                </v-card-text>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="my-5">
          <v-col cols="12" md="4">
            <v-card flat outlined>
              <v-card-title class="EoleBlueLighten">
                <v-col cols="12" md="10">Liste des installateurs</v-col>
                <v-col cols="12" md="2">
                  <v-dialog v-model="dialogAddFranchise" max-width="1000px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="EoleBlue" outlined v-bind="attrs" v-on="on" large>
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                  </v-dialog>
                </v-col>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="no-padding">
                <v-treeview open-all :active.sync="activeInstall" :items="itemsInstall"
                  :open.sync="open" activatable color="EoleBlue" transition
                  selected-color="EoleError">
                </v-treeview>
                <template v-slot:prepend="{ item }">
                  {{ item.name }}
                </template>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions class="justify-end no-padding align-center mt-4 mr-2 EoleBlue--text">
                <p class="text-caption">{{ itemsInstall.length }} installateurs trouvés</p>
                <br />
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="8">
            <v-scroll-y-transition mode="out-in">
              <v-row v-if="!selectedInstall" align="center" class="text-left">
                <v-col>
                  <v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense>
                    Veuillez selectionner un partenaire à afficher dans cette fenêtre.</v-alert>
                </v-col>
              </v-row>
              <v-card v-else :key="selectedInstall.id" class="mx-auto" flat>
                <v-card outlined class="text-center">
                  <v-card-title class="EoleBlueLighten mb-5">
                    <v-row>
                      <v-col class="text-left" cols="12" md="9">{{ selectedInstall.name }}</v-col>
                      <v-col class="text-right" cols="12" md="3">
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-avatar class="avatar bordered" size="128" color="EoleBlue">
                    <div class="hover-overlay">
                      <span class="overlay-text white--text">Changer le logo</span>
                    </div>
                    <i class="fa fa-building fa-3x white--text"></i>
                  </v-avatar>
                  <h3 class="text-h5 mb-2">{{ selectedInstall.name }}</h3>
                  <h4 class="text-h6 mb-2">
                    {{ selectedInstall.street }}, {{ selectedInstall.zip }}
                    ({{ selectedInstall.city }})
                  </h4>
                  <h5 class="text-h6 mb-2">{{ selectedInstall.naf_libelle }}
                    ({{ selectedInstall.naf_code }})</h5>
                  <v-row tag="v-card-text">
                    <v-col class="text-right" cols="12" md="6"> N° de siret : </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import Vue from "vue"
  import Toast from "vue-toastification"
  // Import the CSS or use your own!
  import "vue-toastification/dist/index.css"
  import config from "@/views/config/config"
  import appbar from "@/layouts/Content.vue"
  import emailjs from "@emailjs/browser"
  import {
    init
  } from "@emailjs/browser"
  init("user_gUunL4KW2ImPaGngWHYhP")
  // Import component
  import Loading from "vue-loading-overlay"
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css"

  Vue.use(Toast, {
    maxToasts: 2,
    newestOnTop: true,
  })

  const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

  export default {
    props: ["customerId"],

    mounted() {
      this.$watch("customerId", this.fetchFranchise())
    }, 
    
    data: () => ({
      siretNotFound: false,
      siretNotFoundMessage: "",
      siret: null,
      dataPappers: false,
      pappersInformations: [],
      signatory: [],

      company: {
        postalCode: null,
        address: null,
        city: null,
        nameCompany: null,
        siret: null,
        codeNaf: null,
        libelleNaf: null,
        email: null,
        phone: null,
        representants: [],
      },


      headersDirectors: [
        {
          text: "Civilité",
          align: "start",
          sortable: false,
          value: "sexe",
          width: "10%",
        },
        {
          text: "Nom",
          align: "start",
          sortable: false,
          value: "nom",
          width: "10%",
        },
        {
          text: "Prénom",
          align: "start",
          sortable: false,
          value: "prenom_usuel",
          width: "10%",
        },
        {
          text: "Loisir",
          align: "start",
          sortable: false,
          value: "loisir",
          width: "10%",
        },
      ],

      tabChooseCateSupplier: null,
      dialogAddFranchise: false,

      activeFranchise: [],
      activeInstall: [],
      open: [0],

      itemsFranchise: [
        // {
        //   id: 1, name: "Franchise DUCHEMIN", dateE: "06/05/2019", leaders: "DEOUF", mail: "tartampion@dulac.fr", num: "06.58.69.47.65",
        //   address: "Mon adresse Postale", postalCode: "75000", install: "6", tendCA: "363 000€", ca: "213 000,56€", mesFranchises: ["SARL LEDI", "SARL LOPI"],
        //   attesRC: "XX", attesDec: "XX", naf_libelle: "Travaux de menuiserie bois et PVC", naf_code: "4332A",
        // },
      ],

      itemsFranchiseDisapproved: [],

      itemsInstall: [{
          id: 1,
          name: "INSTALLATEUR 1",
          dateE: "06/05/2019",
          leaders: "DEOUF",
          mail: "tartampion@dulac.fr",
          num: "06.58.69.47.65",
          street: "Mon adresse Postale",
          zip: "75000",
          install: "6",
          tendCA: "363 000€",
          ca: "213 000,56€",
          mesFranchises: ["SARL LEDI", "SARL LOPI"],
          attesRC: "XX",
          attesDec: "XX",
          naf_libelle: "Travaux de menuiserie bois et PVC",
          naf_code: "4332A",
        },
        {
          id: 2,
          name: "INSTALLATEUR 2",
          dateE: "06/08/2016",
          leaders: "SALLOMON",
          mail: "tartampion@dulac.fr",
          num: "06.58.69.47.65",
          street: "Mon adresse Postale",
          zip: "75000",
          install: "6",
          tendCA: "363 000€",
          ca: "213 000,56€",
          mesFranchises: ["SARL LEDI", "SARL LOPI"],
          attesRC: "XX",
          attesDec: "XX",
          naf_libelle: "Travaux de menuiserie bois et PVC",
          naf_code: "4332A",
        },
        {
          id: 3,
          name: "INSTALLATEUR 5",
          dateE: "09/02/2012",
          leaders: "HENRY",
          mail: "tartampion@dulac.fr",
          num: "06.58.69.47.65",
          street: "Mon adresse Postale",
          zip: "75000",
          install: "6",
          tendCA: "363 000€",
          ca: "213 000,56€",
          mesFranchises: ["SARL LEDI", "SARL LOPI"],
          attesRC: "XX",
          attesDec: "XX",
          naf_libelle: "Travaux de menuiserie bois et PVC",
          naf_code: "4332A",
        },
        {
          id: 4,
          name: "INSTALLATEUR 8",
          dateE: "25/09/2022",
          leaders: "GEORGE",
          mail: "tartampion@dulac.fr",
          num: "06.58.69.47.65",
          street: "Mon adresse Postale",
          zip: "75000",
          install: "6",
          tendCA: "363 000€",
          ca: "213 000,56€",
          mesFranchises: ["SARL LEDI", "SARL LOPI"],
          attesRC: "XX",
          attesDec: "XX",
          naf_libelle: "Travaux de menuiserie bois et PVC",
          naf_code: "4332A",
        },
      ],

      itemsInstallDisapproved: [],
    }),

    computed: {
      selectedFranchise() {
        if (!this.activeFranchise.length) return undefined
        const id = this.activeFranchise[0]
        return this.itemsFranchise.find(franchise => franchise.id === id)
      },

      selectedInstall() {
        if (!this.activeInstall.length) return undefined
        const id = this.activeInstall[0]
        return this.itemsInstall.find(franchise => franchise.id === id)
      },

      itemsFranchises() {
        return [{
          name: `Mes entreprises actifs (${this.itemsFranchise.length})`,
          children: this.itemsFranchise,
        }, ]
      },

      itemsInstalls() {
        return [{
          name: `Mes Installateurs actifs (${this.itemsInstall.length})`,
          children: this.itemsInstall,
        }, ]
      },
    },

    methods: {

      addDirector() {
        console.log("custo : ", this.customerId)
        this.company.representants.push({
          nom: "",
          prenom_usuel: "",
          prenom: "",
        })
      },

      async addCustomerPro() {
        let agencyId = localStorage.getItem("agency_id")
        let typo = "prospect"

        console.log("Donnée de l'entreprise : ", this.company)

        try {
          let headers = new Headers()

          headers.append("Content-Type", "application/json")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")

          const response = await fetch(
            `${config.apiUri}/agencies/customer_professional?idAgency=${agencyId}&postalCode=${this.company.postalCode}&city=${this.company.city}
              &address=${this.company.address}&nameCompany=${this.company.nameCompany}&phone=${this.company.phone}&email=${this.company.email}
              &codeNaf=${this.company.codeNaf}&siret=${this.company.siret}&libelleNaf=${this.company.libelleNaf}&typology=${typo}&customerIdFranchise=${this.customerId}`, {
              mode: "no-cors",
              method: "POST",
              headers: headers,
            },
          )
          console.log(response)
          if (response.status === 400) {
            console.log("Eurreur dans l'envoie !")
          } else {
            console.log("Envoyer ! ", response)
            this.$toast.success(`Le client ${this.company.nameCompany} à bien été ajouté.`, {
              position: "bottom-right",
              timeout: 5000,
            })
            this.company = {
              postalCode: null,
              address: null,
              city: null,
              nameCompany: null,
              siret: null,
              codeNaf: null,
              libelleNaf: null,
              email: null,
              phone: null,
              representants: [],
            }
          }
        } catch (e) {
          console.log(e)
          this.$toast.error(`Erreur lors de l'envoie.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
        this.dialogAddFranchise = false
      },

      async fetchInterlocFranchise(idPro){
        let agencyId = localStorage.getItem("agency_id")
        try {
          let headers = new Headers()

          headers.append("Content-Type", "application/json")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")

          const response = await fetch(`${config.apiUri}/agencies/${agencyId}/customer_professional/${idPro}`, {
              mode: "cors",
              method: "GET",
              headers: headers,
          })
          const data = await response.json()
          
          data.forEach(elem => {
            console.log(elem)
            this.itemsFranchise.push({
              id: elem.id,
              name: elem.commercial_name,
              mail: elem.email,
              phone: elem.phone,
              address: elem.address,
              postalCode: elem.postal_code,
              city: elem.city,
              naf_libelle: elem.naf_libelle,
              naf_code: elem.naf_code,
              siret: elem.siret,
              interlocutor: elem.interlocutor,
            })
          })
        }catch(e){
          console.log(e)
        }
        console.log("itemFranchise : ", this.itemsFranchise)
      },

      async fetchFranchise(){
        let agencyId = localStorage.getItem("agency_id")

        try {
          let headers = new Headers()

          headers.append("Content-Type", "application/json")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")

          const response = await fetch(
            `${config.apiUri}/agencies/${agencyId}/customer_professional`, {
              mode: "cors",
              method: "GET",
              headers: headers,
            })
          const data = await response.json()

          data.forEach(elem => {
            // console.log("interloc : ", elem[0].interlocutor)
            if (elem.id_customer_franchise === this.customerId){
              this.fetchInterlocFranchise(elem.id)
            }
          })
        } catch(e) {
          console.log("error for fetch name franchise")
        }
      },

      async fetchPappers(siret) {
        console.log("reponse")
        this.pappersInformations = []
        this.loadingData = true
        // let siret = "53859143900046"

        try {
          let headers = new Headers()

          headers.append("Content-Type", "application/json")
          headers.append("Accept", "application/json")
          headers.append("Origin", "*")

          const response = await fetch(
            `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siret=${siret}`, {
              mode: "cors",
              method: "GET",
              headers: headers,
            },
          )

          if (response.status === 400) {
            this.siretNotFound = true
            this.siretNotFoundMessage = "Impossible de trouver le siret que vous avez renseigné, merci de réessayer"
          } else {
            console.log("reponse2")
            this.siretNotFound = false
            this.siretNotFoundMessage = ""

            let data = await response.json()
            this.pappersInformations.push(data)

            if (data.representants[0].personne_morale === true) {
              let responseSiren = await fetch(`https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siren=${data.representants[0].siren}`,
                {
                  mode: "cors",
                  method: "GET",
                  headers: headers,
                },
              )
              let dataSiren = await responseSiren.json()
              this.company = {
                postalCode: this.pappersInformations[0].siege.code_postal,
                address: this.pappersInformations[0].siege.adresse_ligne_1,
                city: this.pappersInformations[0].siege.ville,
                nameCompany: this.pappersInformations[0].nom_entreprise,
                siret: siret,
                codeNaf: this.pappersInformations[0].siege.code_naf,
                libelleNaf: this.pappersInformations[0].siege.libelle_code_naf,
                representants: [],
              }

              dataSiren.representants.forEach(ceo => {
                if (ceo.qualite === "Gérant") {
                  this.company.representants.push(ceo)
                }
              })
            } else {
              this.company = {
                postalCode: this.pappersInformations[0].siege.code_postal,
                address: this.pappersInformations[0].siege.adresse_ligne_1,
                city: this.pappersInformations[0].siege.ville,
                nameCompany: this.pappersInformations[0].nom_entreprise,
                siret: siret,
                codeNaf: this.pappersInformations[0].siege.code_naf,
                libelleNaf: this.pappersInformations[0].siege.libelle_code_naf,
                representants: this.pappersInformations[0].representants,
              }
            }
            this.dataPappers = true
            console.log(data)
            console.log(this.pappersInformations[0].representants)
          }
          this.loadingData = false
        } catch (e) {
          console.log(e)
        }
      },
    }
  }

</script>
<style lang="scss">
  .hover-overlay {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease-in-out;
  }

  .avatar:hover .hover-overlay {
    opacity: 1;
  }

</style>
